import React from 'react';

const NomineesPetCategory = ({nominees}) => {
     return (
          <section className="nominees-pet-category">
               <div className="container">
                    <div className="row">
                         {nominees.map((nominee) => {
                              const nomineeImage = nominee.nomineesPetFeaturedImage?.file?.url || '/images/defaultHambone.png';
                              const currentYear = nominee.nomineesOfTheYear.nomineesYear;
                              const nomineeSlug = `/hamboneaward/nominees/${currentYear}/${nominee.slug}/`;

                              const capitalizeWords = (str) => {
                                   return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('-');
                              }
                              
                              const slugNominee = nominee.slug;
                              const ariaLabelPetName = slugNominee.split(' ').map(capitalizeWords).join(' ');

                              return (
                                   <div className="col-md-6 col-lg-4 col-xl-3" key={nominee.id}>
                                        <div className="nominees-pet-category-card">
                                             <a href={nomineeSlug} aria-label={ariaLabelPetName}>
                                             <div className="nominees-pet-category-card-image" style={{ backgroundImage: `url(${nomineeImage})` }}>
                                                  <div className="nominees-pet-category-card-name">
                                                       <span>{nominee.nomineesPetName}</span>
                                                  </div>
                                             </div>
                                             </a>
                                             <div className="nominees-pet-category-card-action">
                                                  <a href={nomineeSlug} aria-label={ariaLabelPetName}>{nominee.nomineesPetName}</a>
                                             </div>
                                        </div>
                                   </div>
                              );
                         })}
                    </div>
               </div>
          </section>
     );
};

export default NomineesPetCategory;
