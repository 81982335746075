import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
import NomineesPast from './NomineesPast';
import { ContentfulSectionHamboneBanner } from '..';
import NomineesPetCategory from './NomineesPetCategory';
// import { Pagination } from 'swiper';

const Nominees = ({ nominee, nomineesPet }) => {
     const currentYear = nominee.nomineesYear;

     const listNomineesOfYear = nomineesPet?.filter((item) => item.nomineesOfTheYear?.nomineesYear === currentYear) || [];

     return (
          <>
               {nominee && (
                    <>
                         <ContentfulSectionHamboneBanner {...nominee.nomineesIntro} />
                         {listNomineesOfYear.length > 0 && <NomineesPetCategory nominees={listNomineesOfYear} />}
                    </>
               )}

               <NomineesPast nominee={nominee} currentYear={currentYear} />
          </>
     );
};

export default Nominees;
